import React, { createContext, useEffect, useReducer, useState } from "react";
import { gql } from "@apollo/client";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client/react";
import { CART } from "../lib/apollo/fragments";
import { sendSentryError } from "../lib/sentry/sentry";
import { DiscountT } from "../utils/shop";
import littledata from "@littledata/headless-shopify-sdk";
import { useRouter } from "next/router";
import { initializeApollo } from "@/lib/apollo/apollo-client";
import { facebookPixelId } from "@/utils/pixel";

export const CREATE_CART = gql`
  mutation cartCreate {
    cartCreate {
      cart {
        id
        checkoutUrl
      }
    }
  }
`;

const FETCH_CART = gql`
  ${CART}
  query Cart($cartId: ID!) {
    cart(id: $cartId) {
      estimatedCost {
        subtotalAmount {
          amount
          currencyCode
        }
      }
      ...CART
    }
  }
`;

const UPDATE_CART_ATTRIBUTES = gql`
  mutation cartAttributesUpdate($attributes: [AttributeInput!]!, $cartId: ID!) {
    cartAttributesUpdate(attributes: $attributes, cartId: $cartId) {
      cart {
        id
        checkoutUrl
      }
    }
  }
`;

const REMOVE_FROM_CART = gql`
  ${CART}
  mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
    cartLinesRemove(lineIds: $lineIds, cartId: $cartId) {
      cart {
        ...CART
      }
    }
  }
`;

const FETCH_CART_LINES = gql`
  query Cart($cartId: ID!) {
    cart(id: $cartId) {
      lines(first: 20) {
        edges {
          node {
            id
          }
        }
      }
    }
  }
`;

export enum ShopReducerTypes {
  CreateCart = "CREATE_CART",
  AddToCart = "ADD_TO_CART",
  UpdateCartItem = "UPDATE_CART_ITEM",
  DeleteCartItem = "REMOVE_CART_ITEM",
  AddDiscount = "ADD_DISCOUNT",
  Checkout = "CHECKOUT",
  UpdateAttributes = "UPDATE_ATTRIBUTES",
}

type CartType = {
  id: string;
  checkoutUrl: string;
};

type ShopStateType = {
  cart: CartType;
  discountMap: {
    [productId: string]: DiscountT & {
      code: string;
    };
  };
  cartAttributes: { key: string; value: string }[];
};

const initialState = {
  cart:
    typeof window !== "undefined"
      ? JSON.parse(localStorage.getItem("cart") || "{}")
      : {},
  discountMap: {},
  cartAttributes: [],
};

/**
 * Updates the cart with metadata by sending a mutation to the Apollo client.
 * 
 * @param {string} cartId - The unique identifier for the cart to be updated.
 * @param {{ key: string, value: string }[]} clientIds - An array of objects 
 * containing key-value pairs of metadata to be added to the cart.
 * @returns A promise that resolves with the result of the Apollo mutation.
 */
const updateCartWithMetadata = async (
  cartId: string,
  clientIds: { key: string, value: string }[]
) => {
  const apolloClient = initializeApollo();
  const context = { clientName: "shopify" };
  const attributes = clientIds.map((clientId) => {
    return { key: clientId.key, value: clientId.value };
  });
  return await apolloClient.mutate({
    mutation: UPDATE_CART_ATTRIBUTES,
    variables: {
      attributes: attributes,
      cartId: cartId,
    },
    context: context,
  });
};

/**
 * Opens the Shopify checkout URL in a new tab after attempting to update the
 * cart with metadata from local storage if available.
 * 
 * @param {ShopStateType} state - The current state of the shop, 
 * containing the cart information.
 */
const redirectToCheckout = async (state: ShopStateType) => {
  if (state.cartAttributes.length > 0) {
    try {
      await updateCartWithMetadata(state.cart.id, state.cartAttributes);
      console.debug("Cart updated");
    } catch (error) {
      console.error("Failed to update cart with metadata:", error);
      sendSentryError("Failed to update cart with metadata:", error);
    }
  }
  window.open(state.cart.checkoutUrl, "_self");
};

const ShopReducer = (
  state: ShopStateType,
  action: { type: string; payload?: any }
) => {
  const { type, payload } = action;
  switch (type) {
    case ShopReducerTypes.CreateCart:
      localStorage.setItem("cart", JSON.stringify(payload));
      return { ...state, cart: payload };
    case ShopReducerTypes.AddToCart:
      return { ...state };
    case ShopReducerTypes.UpdateCartItem:
      return { ...state };
    case ShopReducerTypes.DeleteCartItem:
      return { ...state };
    case ShopReducerTypes.AddDiscount:
      return {
        ...state,
        discountMap: {
          ...state.discountMap,
          [payload.productId]: {
            value_type: payload.value_type,
            value: payload.value,
            code: payload.code,
          },
        },
      };
    case ShopReducerTypes.UpdateAttributes:
      if (!Array.isArray(payload)) {
        console.debug("Payload is not an array");
        return state;
      };
      return {
        ...state,
        cartAttributes: [...state.cartAttributes, ...payload],
      };
    case ShopReducerTypes.Checkout:
      redirectToCheckout(state);
      return { ...state };
    default:
      return state;
  }
};

export const ShopContext = createContext<{
  state: ShopStateType;
  dispatch: React.Dispatch<any>;
  clearCart: () => Promise<void>;
}>({
  state: initialState,
  dispatch: () => null,
  clearCart: async () => { },
});

export const useShopContext = () => {
  const context = React.useContext(ShopContext);
  if (context === undefined) {
    throw new Error("useShopContext must be used within a ShopContextProvider");
  }
  return context;
}

//@ts-ignore
export const ShopContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ShopReducer, initialState);
  const [clientIdsLoaded, setClientIdsLoaded] = useState(false);
  const router = useRouter();

  const [createCheckout] = useMutation(CREATE_CART, {
    context: { clientName: "shopify" },
    onCompleted: ({ cartCreate }) => {
      console.debug("cart created", cartCreate?.cart);
      dispatch({
        type: ShopReducerTypes.CreateCart,
        payload: cartCreate?.cart,
      });
    },
    onError: (error) => {
      sendSentryError("shopify mutation cartCreate", error);
      console.error("Failed to create cart", error);
    },
  });

  const createCart = async () => {
    await createCheckout();
  };

  const linkCartToLittledata = (cartId: string) => {
    littledata.sendCartToLittledata(cartId)
      .then((shopifyCartNotes: any) => {
        if (shopifyCartNotes?.length > 0) {
          dispatch({
            type: ShopReducerTypes.UpdateAttributes,
            payload: shopifyCartNotes,
          });
        }
      }).catch((error: any) => {
        sendSentryError("Error sending cart to Littledata", error);
      });
  }

  const clearCart = async () => {
    const cartId = state?.cart?.id;
    if (!cartId) return;

    const cartLineResult = await fetchCartLines({
      variables: {
        cartId: cartId,
      },
    });
    const cartLineIds = cartLineResult.data?.cart?.lines?.edges?.map(
      (edge: any) => edge.node.id
    );

    await cartLinesRemove({
      variables: {
        cartId: cartId,
        lineIds: cartLineIds,
      },
    });
  };

  const { data } = useQuery(FETCH_CART, {
    skip: !(typeof state?.cart?.id === "string" && state.cart.id.startsWith("gid")),
    variables: {
      cartId: state?.cart?.id,
    },
    context: {
      clientName: "shopify",
    },
    onError: (error) => {
      sendSentryError("Failed to fetch cart from Shop Context", error);
    }
  });

  const [cartLinesRemove] = useMutation(REMOVE_FROM_CART, {
    context: { clientName: "shopify" },
    onCompleted: ({ cartLinesRemove }) => {
      console.log("item removed from cart", cartLinesRemove);
      dispatch({
        type: ShopReducerTypes.DeleteCartItem,
        payload: cartLinesRemove,
      });
    },
    onError: (error) => {
      console.log("cartLinesRemove error", error);
    },
  });

  const [fetchCartLines] = useLazyQuery(FETCH_CART_LINES, {
    fetchPolicy: "no-cache",
    context: { clientName: "shopify" },
    onError: (error) => {
      console.error("fetchCartLines error", error);
    },
  });

  useEffect(() => {
    const noLoadedCartId: boolean = !state?.cart?.id;
    const fetchedCartIsNull: boolean = data?.cart === null;
    if (noLoadedCartId || fetchedCartIsNull) {
      console.debug("Creating cart");
      createCart();
    } else if (clientIdsLoaded) {
      console.debug("Linking cart to Littledata");
      linkCartToLittledata(data?.cart?.id);
    }
  }, [state?.cart?.id, data?.cart, clientIdsLoaded]);

  useEffect(() => {
    // Doesn't seem to resolve if ad block is enabled
    littledata.fetchClientIds({
      ga4MeasurementId: process.env.NEXT_PUBLIC_GA4_MEASUREMENT_ID,
      segmentWriteKey: process.env.NEXT_PUBLIC_SEGMENT_UNAUTHENTICATED_ANALYTICS_KEY,
      ...(process.env.NODE_ENV === "production" && facebookPixelId ?
        { fbPixelId: facebookPixelId } : {}
      ),
    }).then((_clientIds: any) => {
      setClientIdsLoaded(true);
    }).catch((error: any) => {
      sendSentryError("Error fetching client ids", error);
    });
  }, [router.route]);

  return (
    <ShopContext.Provider value={{ state, dispatch, clearCart }}>
      {children}
    </ShopContext.Provider>
  );
};
